
export default {
	name: "ProjectList",
	data() {
		return {
			httpHeader: {
				token: this.getCookie("token")
			},
			uploadFile: '',
			gUserId: "",
			tableList: [],
			departmentName: '',
			departmentId: '',
			searchMechanism: '',
			fileList: [],
			centerDialogVisible: false,
			isAddMechanism: false,
			aa: '张三-13812345678',
			aaa: '张三四-13812345678',
			addDepartmentName: '',
			uplaodExcelUrl: '',
			page: 1,
			total: '',
			pageSize: '',
			diseaseName: '',
			porject: {
				standby1: '',//第一个id
				projectNum: '',
				shortName: '',
				projectName: '',
				diseaseId: '', //适应症id
				diseaseName: '',
				standby2: '',
				enterpriseId: '',//所属申办方id
				enterpriseName: ''//所属申办方名称
			},
			indicationList: [],
			isShowIndicationList: true,
			isAdd: false,
			porjectIndex: '',
			xmNumber: '',
			xmShrotName: '',
			xmName: '',
			xmSyz: '',
			xmFq: '',
			xmSbf: '',
			isAddPorject: false,
			optionsArr: ['CRO', '申办方'],
			options: [{
				label: '申办方',
				value: 2
			}, {
				label: 'CRO',
				value: 1
			}],
			standby2Arr: [{
				label: 'Ⅰ期',
				value: 'Ⅰ期'
			}, {
				label: 'Ⅱ期',
				value: 'Ⅱ期'
			}, {
				label: 'Ⅲ期',
				value: 'Ⅲ期'
			}, {
				label: 'Ⅳ期',
				value: 'Ⅳ期'
			}, {
				label: 'PK',
				value: 'PK'
			}, {
				label: 'BE',
				value: 'BE'
			}, {
				label: '其他',
				value: '其他'
			}],
			porjectEnterprise: [],
			enterpriseName: ''
		};
	},

	mounted() { },
	created() {
		this.token = this.getCookie("token");
		this.gUserId = this.$route.params.id;
		this.uplaodExcelUrl = this.craUrl + "/uploadFile"
		if (this.token && this.gUserId) {
			this.getDepartmentList();
			this.getTitle()
		}
	},

	methods: {
		getDepartmentList() {
			var _this = this
			_this.$http
				.post(
					_this.craUrl + "/projectList", {
					enterpriseId: _this.gUserId,
					page: _this.page
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						_this.tableList = res.data.data.records;
						_this.total = res.data.data.total,
							_this.pageSize = res.data.data.size
						_this.xmNumber = ''
						_this.xmShrotName = ''
						_this.xmName = ''
						_this.xmSyz = ''
					} else if (res.data.code == 401) {
						_this.$router.push("/");
					}
				});
		},
		getTitle() {
			var _this = this;
			_this.$http
				.post(
					_this.craUrl + "/selectOneEnterprise", {
					enterpriseId: _this.gUserId,
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						_this.departmentName = res.data.data.name
						_this.departmentId = res.data.data.id
					} else if (res.data.code == 401) {
						_this.$router.push("/");
					}
				});
		},
		// 新增机构
		importMechanism() {
			this.centerDialogVisible = true;
		},

		handleExceed(files, fileList) {
			this.$message.warning(`只能上传 1 个文件`);
		},
		// 新增科室
		addMechanism(index) {
			this.porjectIndex = index
			this.isAddMechanism = true

			var _this = this
			if (_this.tableList[index]) {
				_this.isAddPorject = false
				_this.porject = {
					id: _this.tableList[index].id,
					standby1: _this.departmentId,
					projectNum: _this.tableList[index].projectNum,
					shortName: _this.tableList[index].shortName,
					projectName: _this.tableList[index].projectName,
					diseaseId: _this.tableList[index].diseaseId,
					diseaseName: _this.tableList[index].diseaseName,
					standby2: _this.tableList[index].standby2,
					enterpriseId: _this.tableList[index].enterpriseId,
					enterpriseName: _this.tableList[index].enterpriseName
				}

				_this.xmNumber = _this.tableList[index].projectNum
				_this.xmShrotName = _this.tableList[index].shortName
				_this.xmName = _this.tableList[index].projectName
				_this.xmSyz = _this.tableList[index].diseaseName
				_this.xmFq = _this.tableList[index].standby2
				_this.xmSbf = _this.tableList[index].enterpriseName

				_this.diseaseName = _this.tableList[index].diseaseName
				_this.enterpriseName = _this.tableList[index].enterpriseName
				setTimeout(function () {
					_this.indicationList = []
				}, 100)
			} else {
				_this.isAddPorject = true
				_this.porject = {
					id: '',
					standby1: _this.departmentId,
					projectNum: '',
					shortName: '',
					projectName: '',
					diseaseId: '',
					diseaseName: '',
					standby2: ''
				}
				_this.diseaseName = ''
				setTimeout(function () {
					_this.indicationList = []
				}, 100)
			}
			setTimeout(function () {
				var oInput = document.getElementById("xmbh");
				oInput.focus()
			}, 200)
		},
		// 删除
		deleteDepartment(index) {
			var _this = this;
			if (!_this.tableList[index].researcherNames) {
				this.$confirm("此操作将永久删除该机构, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				})
					.then(() => {
						_this.$http.post(_this.craUrl + '/isDelProject', {
							projectId: _this.tableList[index].id
						}, {
							headers: {
								"token": _this.token
							}
						}).then(function (res) {
							if (res.data.code == 200) {
								if (res.data.data) {
									_this.$http
										.post(
											_this.craUrl + "/delProject", {
											projectId: _this.tableList[index].id
										}, {
											headers: {
												token: _this.token
											}
										}
										)
										.then(function (res) {
											if (res.data.code == 200) {
												_this.getDepartmentList();
												_this.$message({
													type: "success",
													message: "删除成功!"
												});

											}
										});

								} else {
									_this.$message({
										type: "success",
										message: "不可删除该项目!"
									});
								}

							}
						})

					})
					.catch(() => {

					});
			} else {
				_this.$message({
					type: "warning",
					message: "不可删除!"
				});
			}

		},
		// 保存+
		saveDepartmentInfo() {
			var _this = this
			console.log(_this.porject)
			if (!_this.isAddPorject) {
				if (_this.xmNumber == _this.porject.projectNum && _this.xmShrotName == _this.porject.shortName && _this.xmName ==
					_this.porject.projectName && _this.xmSyz == _this.porject.diseaseName && _this.xmSbf == _this.porject.enterpriseName && _this.xmFq == _this.porject.standby2) {
					_this.isAddMechanism = false
					return
				}
			}

			if (!_this.porject.projectNum) {
				_this.$message({
					type: "warning",
					message: "请填写项目编号!"
				});
			} else if (!_this.porject.shortName) {
				_this.$message({
					type: "warning",
					message: "请填写项目简称!"
				});
			} else if (!_this.porject.projectName) {
				_this.$message({
					type: "warning",
					message: "请填写项目名称!"
				});
			} else if (!_this.porject.diseaseName) {
				_this.$message({
					type: "warning",
					message: "请填写项目适应症!"
				});
			} else if (!_this.porject.standby2) {
				_this.$message({
					type: "warning",
					message: "请选择临床试验分期!"
				});
			} else if (!_this.porject.enterpriseName) {
				_this.$message({
					type: "warning",
					message: "请选择所属申办方!"
				});
			} else if (_this.isAdd) {
				if (_this.isRepeatNum) {
					_this.$message({
						type: "warning",
						message: "项目编号已存在!"
					});
				} else {
					_this.$http.post(_this.craUrl + '/addOrUpdateProject', {
						project: _this.porject
					}, {
						headers: {
							"token": _this.token
						}
					}).then(function (res) {
						if (res.data.code == 200) {
							_this.getDepartmentList();
							_this.isAddMechanism = false
						}
					})
				}
			} else {
				_this.$http.post(_this.craUrl + '/addOrUpdateProject', {
					project: _this.porject
				}, {
					headers: {
						"token": _this.token
					}
				}).then(function (res) {
					if (res.data.code == 200) {
						_this.getDepartmentList();
						_this.isAddMechanism = false
						_this.isAdd = false
					}
				})
			}

		},
		handleAvatarSuccess(res, file) {
			this.uploadFile = res.data.name;
		},
		// 删除上传文件
		handleRemove(file, fileList) {
			this.uploadFile = ''
		},
		// 保存导入的内容
		saveUplaodExcelUrl() {
			console.log(this.fileList)
			var _this = this
			_this.$http
				.post(
					_this.craUrl + "/uplaodExcelProject", {
					enterpriseId: _this.departmentId,
					fileUrl: _this.uploadFile
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						_this.getDepartmentList()
						_this.centerDialogVisible = false
					}
				});
		},
		verifyProjectNum() {
			var _this = this
			if (_this.tableList[_this.porjectIndex]) {
				if (_this.tableList[_this.porjectIndex].projectNum != _this.porject.projectNum) {
					_this.isAdd = true
					_this.$http
						.post(
							_this.craUrl + "/projectNumcheck", {
							projectNum: _this.porject.projectNum,
						}, {
							headers: {
								token: _this.token
							}
						}
						)
						.then(function (res) {
							if (res.data.code == 200) {
								if (res.data.data) {
									_this.isRepeatNum = true
									_this.$message({
										type: "warning",
										message: "该项目编号已存在，请重新输入!"
									});
								} else {
									_this.isRepeatNum = false
								}
							}
						});
				}
			} else {

				_this.$http
					.post(
						_this.craUrl + "/projectNumcheck", {
						projectNum: _this.porject.projectNum,
					}, {
						headers: {
							token: _this.token
						}
					}
					)
					.then(function (res) {
						if (res.data.code == 200) {
							if (res.data.data) {
								_this.isAdd = true
								_this.isRepeatNum = true
								_this.$message({
									type: "warning",
									message: "该项目编号已存在，请重新输入!"
								});
							} else {
								_this.isRepeatNum = false
							}
						}
					});
			}


		},
		// 分页
		handleCurrentChange(val) {
			this.page = val;
			this.getDepartmentList();
		},
		// 获取适应症列表
		getIndicationList(e) {
			console.log(e)
			var _this = this
			_this.$http
				.post(
					_this.craUrl + "/selectDiseaseByName", {
					diseaseName: e,
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						if (res.data.data) {
							_this.indicationList = res.data.data
						}
					}
				});
		},
		selectIndication(item) {
			var that = this
			this.diseaseName = item.name;
			this.porject.diseaseName = item.name
			this.porject.diseaseId = item.id
			setTimeout(function () {
				that.indicationList = []
			}, 100)
		},
		addCrc(index) {
			this.$router.push({
				name: "crcMyList",
				params: {
					id: this.tableList[index].id,
					projectId: this.gUserId
				}
			});
		},
		getEnterpriseAllName(e) {
			console.log(e)
			var _this = this
			_this.$http
				.post(
					_this.craUrl + "/selectEnterpriseByLikeName", {
					standby1: 2,
					enterpriseName: e
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						if (res.data.data) {
							_this.porjectEnterprise = res.data.data
							_this.porject.enterpriseName = _this.enterpriseName
							_this.porject.enterpriseId = ''
						}
					}
				});
		},
		selectEnterprise(item) {
			var that = this
			this.enterpriseName = item.name;
			this.porject.enterpriseName = item.name
			this.porject.enterpriseId = item.id
			setTimeout(function () {
				that.porjectEnterprise = []
			}, 100)
		}
	},
	watch: {
		diseaseName: function (newQuestion, oldQuestion) {
			this.porject.diseaseName = newQuestion
			this.getIndicationList(newQuestion)
		}
	},
};
